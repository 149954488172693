import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import CheckMarkIcon from '../icons/CheckMarkIcon';

const Pricing = () => {
  const auth = useAuth();
  let dynamicButton = auth.isAuthenticated() ? (
    ''
  ) : (
    <div className="row">
      <Link to="/login" className="btn btn-block btn-slate btn-rounded">
        Inloggen
      </Link>
      <Link to="/register" className="btn btn-block btn-slate btn-rounded">
        Account aanmaken
      </Link>
    </div>
  );
  dynamicButton = null; // TODO: fix this
  return (
    <>
      <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Prijzen
            </h2>
            <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Door middel van onze codereviews krijgt u een beter inzicht in het
              niveau van uw kandidaat. U kunt hen helpen door eventuele twijfels
              over het niveau weg te nemen of waardevolle feedback te ontvangen
              tijdens sollicitaties waarbij assessments vereist zijn. Wij
              hanteren de volgende prijzen voor onze codereviews:
            </p>
          </div>
          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">Basis CodeReview</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Ons standaard beoordelingspakket biedt een grondige analyse van
                de programmeervaardigheden van uw kandidaat. We garanderen een
                snelle beoordeling binnen 3 werkdagen na ontvangst van de code.
              </p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">€79,99</span>
                <span class="text-gray-500 dark:text-gray-400">per review</span>
              </div>
              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Inschatting niveau (junior/medior/senior)</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Uw hulpvraag wordt beantwoordt</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>
                    Behandeltijd:{' '}
                    <span class="font-semibold">binnen 3 werkdagen</span>
                  </span>
                </li>
              </ul>
              <a
                href="#"
                class="text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:ring-slate-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-slate-900"
              >
                Get started
              </a>
              {dynamicButton}
            </div>
            <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">Spoed CodeReview</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Ons spoedbeoordelingspakket biedt een snelle en grondige analyse
                van de programmeervaardigheden van uw kandidaat. We garanderen
                een supersnelle beoordeling binnen 24 uur na ontvangst van de
                code.
              </p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">€149,99</span>
                <span class="text-gray-500 dark:text-gray-400">per review</span>
              </div>
              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Inschatting niveau (junior/medior/senior)</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Uw hulpvraag wordt beantwoordt</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>
                    Behandeltijd:{' '}
                    <span class="font-semibold">binnen 24 uur</span>
                  </span>
                </li>
              </ul>
              <a
                href="#"
                class="text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:ring-slate-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-slate-900"
              >
                Get started
              </a>
              {dynamicButton}
            </div>

            <div class="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">
                Abonnement 10 codereviews
              </h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Best for large scale uses and extended redistribution rights.
              </p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">€599</span>
                <span class="text-gray-500 dark:text-gray-400">per maand</span>
              </div>
              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Inschatting niveau (junior/medior/senior)</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>Uw hulpvraag wordt beantwoordt</span>
                </li>
                <li class="flex items-center space-x-3">
                  <CheckMarkIcon />
                  <span>
                    Behandeltijd:{' '}
                    <span class="font-semibold">binnen 2 werkdagen</span>
                  </span>
                </li>
              </ul>
              <a
                href="#"
                class="text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:ring-slate-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-slate-900"
              >
                Get started
              </a>
              {dynamicButton}
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container"> */}
      {/*   <div className="card-columns mb-3 text-center"> */}
      {/*     <div className="card mb-4 box-shadow"> */}
      {/*       <div className="card-header"> */}
      {/*         <h4 className="my-0 font-weight-normal">Basis CodeReview</h4> */}
      {/*       </div> */}
      {/*       <div className="card-body"> */}
      {/*         <h1 className="card-title pricing-card-title"> */}
      {/*           €79,99 <small className="text-muted"> per codereview</small> */}
      {/*         </h1> */}
      {/*         <ul className="list-unstyled mt-3 mb-4"> */}
      {/*           <li>Afhandeling binnen 3 werkdagen</li> */}
      {/*           <li>Prijs is incl. BTW</li> */}
      {/*         </ul> */}
      {/*         <p className="text-left"> */}
      {/*           Ons standaard beoordelingspakket biedt een grondige analyse van */}
      {/*           de programmeervaardigheden van uw kandidaat. We garanderen een */}
      {/*           snelle beoordeling binnen 3 werkdagen na ontvangst van de code. */}
      {/*         </p> */}
      {/*         <p className="text-left"> */}
      {/*           Dit pakket is ideaal voor klanten die een betrouwbare en */}
      {/*           kosteneffectieve manier willen om de programmeervaardigheden van */}
      {/*           hun kandidaten te beoordelen. */}
      {/*         </p> */}
      {/*         {auth.isAuthenticated() && ( */}
      {/*           <Link */}
      {/*             to="/code-reviews/new/1wLhYHSMCMKIkyQACfZNQLecgZd" */}
      {/*             className="btn btn-block btn-slate btn-rounded" */}
      {/*           > */}
      {/*             Basis Codereview Aanvragen */}
      {/*           </Link> */}
      {/*         )} */}
      {/*         {dynamicButton} */}
      {/*       </div> */}
      {/*     </div> */}
      {/*     <div className="card mb-4 box-shadow"> */}
      {/*       <div className="card-header"> */}
      {/*         <h4 className="my-0 font-weight-normal">Spoed CodeReview</h4> */}
      {/*       </div> */}
      {/*       <div className="card-body"> */}
      {/*         <h1 className="card-title pricing-card-title"> */}
      {/*           €149,99 <small className="text-muted"> per codereview</small> */}
      {/*         </h1> */}
      {/*         <ul className="list-unstyled mt-3 mb-4"> */}
      {/*           <li>Afhandeling binnen 24 uur</li> */}
      {/*           <li>Prijs is incl. BTW</li> */}
      {/*         </ul> */}
      {/*         <p className="text-left"> */}
      {/*           Ons spoedbeoordelingspakket biedt een snelle en grondige analyse */}
      {/*           van de programmeervaardigheden van uw kandidaat. We garanderen */}
      {/*           een supersnelle beoordeling binnen 24 uur na ontvangst van de */}
      {/*           code. */}
      {/*         </p> */}
      {/*         <p className="text-left"> */}
      {/*           Dit pakket is ideaal voor klanten die een dringende beoordeling */}
      {/*           van de programmeervaardigheden van hun kandidaten nodig hebben */}
      {/*           en die bereid zijn om iets meer te betalen voor een snellere */}
      {/*           dienstverlening. */}
      {/*         </p> */}
      {/*         <p className="text-left"> */}
      {/*           We bieden aantrekkelijke kortingen aan voor bulkbestellingen. */}
      {/*           Neem contact met ons op voor meer informatie over hoe we u */}
      {/*           kunnen helpen bij het optimaliseren van uw recruitmentproces en */}
      {/*           het vinden van de beste IT-talenten. */}
      {/*         </p> */}
      {/*         {auth.isAuthenticated() && ( */}
      {/*           <Link */}
      {/*             to="/code-reviews/new/1wLhYCPtl2CuNdgDnwSE9cJmewW" */}
      {/*             className="btn btn-block btn-slate btn-rounded" */}
      {/*           > */}
      {/*             Spoed Codereview Aanvragen */}
      {/*           </Link> */}
      {/*         )} */}
      {/*         {dynamicButton} */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
    </>
  );
};

export default Pricing;
