import React from 'react';

const AccessRequested = ({ handelsnaam }) => {
  return (
    <>
      <h2>Toegang aangevraagd</h2>
      <p>
        U heeft toegang aangevraagd tot <em>{handelsnaam}</em>. De
        contactpersoon van dit bedrijf heeft een email ontvangen met uw verzoek
        tot toegang. Wacht geduldig af tot dit verzoek geaccepteerd of afgewezen
        is, u ontvangt een e-mail wanneer de status veranderd.
      </p>
    </>
  );
};

export default AccessRequested;
