/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useNotification } from '../hooks/notification-context';
import Loading from '../components/Loading';
import { DEFAULT_REDIRECT_NO_ORGANIZATION, DEFAULT_REDIRECT_ON_SUCCESSFULL_LOGIN } from '../constants';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();
  const next = query.get('next') || DEFAULT_REDIRECT_ON_SUCCESSFULL_LOGIN;

  useEffect(() => {
    if (
      auth.getCurrentUser() !== null &&
      auth.getCurrentUser().username !== undefined
    ) {
      navigate('/code-reviews');
    }
  }, []);

  const onSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    auth
      .signin(email, password)
      .then((/* username */) => {
        // username is returned by signin promise
        return auth
          .getUserAttribute('custom:company-id')
          .then((companyId) => {
            console.log(`companyId: ${companyId}`);
            console.log(`next: ${next}`);
            navigate(next);
          })
          .catch(() => {
            navigate(DEFAULT_REDIRECT_NO_ORGANIZATION);
          });
      })
      .catch((err) => {
        // TODO: replace with user warning
        // eslint-disable-next-line no-console
        console.error(err);
        addNotification(err.message, 'error');
        if (err.code === 'NotAuthorizedException') {
          setEmail('');
          setPassword('');
        }
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="az-signin-wrapper">
        <div className="az-card-signin">
          <h1 className="az-logo">
            <Link to="/">niveaubepaling.nl</Link>
          </h1>
          <div className="az-signin-header">
            {/* <h2>Welkom terug!</h2> */}
            <h4>Log in om codereviews aan te vragen</h4>

            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="username">E-mailadres</label>
                <input
                  id="username"
                  className="form-control"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              {/* form-group */}
              <div className="form-group">
                <label htmlFor="password">Wachtwoord</label>
                <input
                  id="password"
                  className="form-control"
                  value={password}
                  type="password"
                  placeholder="Enter your password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              {/* form-group */}
              <Button type="submit" className="btn btn-az-primary btn-block">
                Inloggen
              </Button>
            </form>
          </div>
          {/* az-signin-header */}
          <div className="az-signin-footer">
            <p>
              <Link to="/forgot-password">Wachtwoord vergeten?</Link>
            </p>
            <p>
              Heeft u nog geen account?{' '}
              <Link to="/register">Account aanmaken</Link>
            </p>
          </div>
          {/* az-signin-footer */}
        </div>
        {/* az-card-signin */}
      </div>
      {/* az-signin-wrapper */}
    </div>
  );
};

export default Login;
