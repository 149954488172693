/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';

const RegisterCompanyForm = ({
  kvkNumber,
  handelsnaam,
  userEmail,
  registerCompanyCallback,
}) => {
  if (!kvkNumber) {
    return <Navigate to="/register-company" />;
  }

  const defaultValues = {
    'company.name': handelsnaam,
    'company.kvkNumber': kvkNumber,
    'company.invoiceEmailAddress': userEmail,
    company: {
      name: handelsnaam,
      kvkNumber,
      invoiceEmailAddress: userEmail,
    },
  };
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors, touchedFields, isSubmitting, isSubmitSuccessful },
    // reset,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const onSubmit = (data) => {
    // TODO: error handling
    try {
      const res = registerCompanyCallback(data.company);
    } catch (err) {
      console.err(err);
    }
    // TODO companyId op user refrehsen somehow
  };

  if (isSubmitSuccessful) {
    return (
      <div>
        <h1>Bedrijfsregistratie succesvol</h1>
        <p>Uw registratie is gelukt.</p>
        <p>U kunt nu uw eerste codereview aanvraag indien.</p>
        <Link to="/code-reviews/new">
          <Button variant="primary" type="submit">
            Aanvragen!
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <h2>Bedrijf registreren</h2>
      <p>
        Vul de velden in en druk op Bedrijf Registeren om bedrijf{' '}
        <em>{handelsnaam}</em> te registeren.
      </p>

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting}>
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formKvkNumber"
          >
            <Form.Label>KVK nummer</Form.Label>
            <Form.Control
              readOnly
              type="text"
              placeholder="KVK nummer"
              {...register('company.kvkNumber', { required: true })}
              isInvalid={
                touchedFields.company?.kvkNumber && errors.company?.kvkNumber
              }
              isValid={
                touchedFields.company?.kvkNumber &&
                errors.company?.kvkNumber === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.company?.kvkNumber?.message}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              We&apos;ll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formCompanyName"
          >
            <Form.Label>Bedrijfsnaam</Form.Label>
            <Form.Control
              type="text"
              placeholder="Bedrijfsnaam"
              {...register('company.name', { required: true })}
              isInvalid={touchedFields.company?.name && errors.company?.name}
              isValid={
                touchedFields.company?.name &&
                errors.company?.name === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.company?.name?.message}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              Om de bedrijfnaam op deze site weer te geven wordt dit veld
              gebruikt. Dit veld mag minimaal afwijken van de op KVK
              geregistreerde handelsnaam. Indien u een bedrijfsnaam opgeeft die
              niet overeenkomt met uw geregistreerde handelsnaam wordt dit
              account onbruikbaar.
            </Form.Text>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>E-mailadres Contactpersoon</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mailadres bedrijf"
              {...register('company.invoiceEmailAddress', {
                required: true,
                validate: (value) =>
                  validator.isEmail(value) || 'Vul een valide e-mailadres in',
              })}
              isInvalid={
                touchedFields.company?.invoiceEmailAddress &&
                errors.company?.invoiceEmailAddress
              }
              isValid={
                touchedFields.company?.invoiceEmailAddress &&
                errors.company?.invoiceEmailAddress === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.company?.invoiceEmailAddress?.message}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              Naar dit e-mailadres worden status-updates en facturen gestuurd.
              Dit e-mailadres mag afwijken van het e-mailadres dat u gebruikte
              om uw gebruiker te registreren. U kunt dit later nog aanpassen.
            </Form.Text>
          </Form.Group>

          <Button variant="primary" type="submit">
            Bedrijf Registreren
          </Button>
        </fieldset>
      </Form>
    </div>
  );
};

RegisterCompanyForm.propTypes = {
  kvkNumber: PropTypes.string.isRequired,
  handelsnaam: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  registerCompanyCallback: PropTypes.func.isRequired,
};

export default RegisterCompanyForm;
