/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { ProvideAuth } from './hooks/use-auth';
import Signup from './pages/Signup';
import Pricing from './pages/Pricing';
import SignupVerification from './pages/SignupVerification';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import CodeReviews from './pages/CodeReviews';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Home from './pages/Home';
import Home2 from './pages/Home2';
import Notification from './components/Notification';
import NotificationProvider from './hooks/notification-context';
import RegisterCompany from './pages/RegisterCompany';
import ForgotPassword from './pages/ForgotPassword';
import ReviewAccess from './pages/ReviewAccess';
import Invoices from './pages/Invoices';
import ReviewAccessStatus from './pages/ReviewAccessStatus';
import RequireAuth from './components/RequireAuth';
import Profile from './pages/Profile';
import ChooseOrgranization from './pages/ChooseOrganization';
import { CookieConsentProvider } from './hooks/cookie-consent';
import CookieWall from './components/CookieWall';

function App() {
  const location = useLocation();
  const isFullPageLayout = ['/login', '/register', '/'].includes(
    location.pathname,
  );
  const header = isFullPageLayout ? '' : <Header />;
  const footer = isFullPageLayout ? '' : <Footer />;
  let containerClassname = isFullPageLayout ? '' : 'az-content-wrapper';
  if (location.pathname === '/home') {
    containerClassname = '';
  }
  return (
    <Suspense fallback="">
      <CookieConsentProvider>
        <CookieWall />
        <ProvideAuth>
          <NotificationProvider>
            <Notification />
            {header}
            <div className={containerClassname}>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route
                  path="register-company/*"
                  element={<RegisterCompany />}
                />
                <Route
                  path="choose-organization"
                  element={<ChooseOrgranization />}
                />
                <Route path="register" element={<Signup />} />
                <Route
                  path="validate/:emailAddress?"
                  element={<SignupVerification />}
                />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="profile" element={<Profile />} />
                <Route path="code-reviews/*" element={<CodeReviews />} />
                {/* <Route path="" exact element={<Home />} /> */}
                <Route path="" exact element={<Home2 />} />
                <Route
                  path="/review-access/:accessRequestId"
                  element={
                    <RequireAuth>
                      <ReviewAccess />
                    </RequireAuth>
                  }
                />
                <Route path="invoices" element={<Invoices />} />
                <Route
                  path="review-access-status"
                  element={<ReviewAccessStatus />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            {footer}
          </NotificationProvider>
        </ProvideAuth>
      </CookieConsentProvider>
    </Suspense>
  );
}

export default App;
