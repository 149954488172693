import React from 'react';
import PropTypes from 'prop-types';

const statusToClassMap = {
  open: 'secondary', // light grey
  reviewing: 'primary', // blue
  feedback_provided: 'success', // green
  invoiced: 'info', // lighter blue
  done: 'light',
  rejected: 'danger',
};
// https://getbootstrap.com/docs/4.4/components/badge/#contextual-variations

const StatusBadge = ({ status }) => {
  const statusClass = statusToClassMap[status] || 'info';
  return (
    <span className={`badge badge-${statusClass}`}>
      {status.replace('_', ' ')}
    </span>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
