import React, { useEffect, useState } from 'react';
import { Link } from '../../node_modules/react-router-dom/dist/index';

const ChooseOrgranization = () => {

    const startCodeReview = async () => {
        setReviewing(true);
        const api = new CodeReviewApi(await auth.getJwtToken());
        // TODO: how to share api instance between useEffect and other closures?
        await api.updateCodeReview(codereviewId, { status: 'reviewing' });
        // TODO: add error handling
        setReview({ ...review, status: 'reviewing' });
    };

    return (
        <>
            <div className="container az-content-app p-0">
                <div className="az-content">
                    <p>Kies je organisatie vorm:</p>
                    <div className="card-deck">
                        {/* <div class="card"> */}
                        {/*     <img src="https://picsum.photos/200/80?id=1" class="card-img-top" alt="..."></img> */}
                        {/*     <div class="card-body"> */}
                        {/*         <h5 class="card-title">Ik ben developer</h5> */}
                        {/*         <p class="card-text">Je bent een developer en je wilt graag je code laten controleren voordat je deze inlevert, bijvoorbeeld vlak voor je sollicitatieronde.</p> */}
                        {/*         <a href="#" class="btn btn-primary">Start als developer</a> */}
                        {/*     </div> */}
                        {/* </div> */}
                        {/**/}
                        {/* <div class="card"> */}
                        {/*     <img src="https://picsum.photos/200/80?id=2" class="card-img-top" alt="..."></img> */}
                        {/*     <div class="card-body"> */}
                        {/*         <h5 class="card-title">Ik ben zelfstandige</h5> */}
                        {/*         <p class="card-text">Je bent een zelfstandige die code reviews wilt aanvragen, je hebt geen collega's.</p> */}
                        {/*         <a href="#" class="btn btn-primary">Start als zelfstandige</a> */}
                        {/*     </div> */}
                        {/* </div> */}

                        <div class="card">
                            <img src="https://picsum.photos/200/80?id=3" class="card-img-top" alt="..."></img>
                            <div class="card-body">
                                <h5 class="card-title">Organisatie registreren</h5>
                                <p class="card-text">Kies deze optie indien je een recruiter bent die bij een bedrijf hoort of een nieuwe registratie wilt doen.</p>
                                <Link to="/register-company" class="btn btn-primary">Organisatie registreren</Link>
                            </div>
                        </div>

                        <div class="card">
                            <img src="https://picsum.photos/200/80?id=4" class="card-img-top" alt="..."></img>
                            <div class="card-body">
                                <h5 class="card-title">Mijn organisatie is al door een ander geregistreerd</h5>
                                <p class="card-text">Als je bedrijf al bestaat vraag dan je teamlead om je uit te nodigen, vraag hem/haar dan om je uit te nodigen tot die organisatie.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
};

export default ChooseOrgranization;
