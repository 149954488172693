import React from 'react';

function ReviewAccessStatus() {
  return (
    <div className="container">
      <div>
        <h1>Toegang status</h1>
        <p>
          Voordat u Codereviews kunt aanvragen zal uw toegang eerst goedgekeurd
          moeten worden door de contactpersoon bij het bedrijf waaraan u
          gekoppeld wilt worden.
        </p>
      </div>
    </div>
  );
}

export default ReviewAccessStatus;
