import React, { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CodeReviewApi from '../Api';
import { useAuth } from '../hooks/use-auth';

const EditReviewFeedback = ({ codereview, setCodereview, reviewing }) => {
  const [reviewFeedback, setReviewFeedback] = useState(codereview.comment);
  const auth = useAuth();
  const saveCodeReviewComment = async () => {
    const api = new CodeReviewApi(await auth.getJwtToken());
    const responseData = await api.updateCodeReview(codereview.id, {
      comment: reviewFeedback,
    });
    setCodereview({ ...codereview, ...responseData });
  };

  if (!reviewing) {
    return <MDEditor.Markdown source={reviewFeedback} />;
  }

  return (
    //   eslint-disable-next-line
    <div>
      <Button variant="primary" type="button" onClick={saveCodeReviewComment}>
        Opslaan
      </Button>
      <MDEditor value={reviewFeedback} onChange={setReviewFeedback} />
    </div>
  );
};

EditReviewFeedback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  codereview: PropTypes.object.isRequired,
  setCodereview: PropTypes.func.isRequired,
  reviewing: PropTypes.bool.isRequired,
};

export default EditReviewFeedback;
