/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const idStyle = {
  fontFamily: 'monospace',
};

const CodeReviewId = ({ companyId, codeReviewId }) => {
  return (
    <div style={idStyle}>
      <Link to={`/code-reviews/${companyId}/${codeReviewId}`}>
        {codeReviewId}
      </Link>{' '}
      <span
        onClick={() => {
          navigator.clipboard.writeText(codeReviewId);
        }}
        title={`Copy ${codeReviewId} to clipboard`}
      >
        <i className="typcn typcn-clipboard" />
      </span>
    </div>
  );
};

CodeReviewId.propTypes = {
  companyId: PropTypes.string.isRequired,
  codeReviewId: PropTypes.string.isRequired,
};

export default CodeReviewId;
