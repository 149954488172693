/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserPool from '../UserPool';
import { useNotification } from '../hooks/notification-context';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState();

  const { addNotification } = useNotification();

  const onSubmit = (event) => {
    event.preventDefault();

    UserPool.signUp(
      email,
      password,
      [
        { Name: 'phone_number', Value: phoneNumber },
        { Name: 'name', Value: name },
      ],
      null,
      (err, data) => {
        if (err) {
          console.error(err);
          addNotification(err.message || JSON.stringify(err), 'error');
        } else {
          console.log(data);
          setRegisterSuccess(true);
        }
      },
    );
  };

  if (registerSuccess) {
    return <Navigate to={`/validate/${email}`} />;
  }

  return (
    <div>
      <div className="az-signup-wrapper">
        <div className="az-column-signup-left">
          <div>
            <i className="typcn typcn-chart-bar-outline" />
            <h1 className="az-logo">
              <Link to="/">niveaubepaling.nl</Link>
            </h1>
            <h5>Niveau-zekerheid op basis van professionele CodeReviews</h5>
            <p>
              Heeft u soms twijfel over het niveau van uw kandidaat of wilt u
              gewoon extra zekerheid hierover? Niveaubepaling.nl gaat op de
              volgende manier te werk:
              <ol>
                <li>U registreert uw account op niveaubepaling.nl</li>
                <li>
                  U dient in overleg met uw kandidaat een codereview aanvraag in
                </li>
                <li>
                  Er wordt een CodeReview gedaan voor de door uw kandidaat
                  gemaakte broncode
                </li>
                <li>
                  Aan de hand van de terugkoppeling die wij u geven kunt u een
                  inschatting krijgen van het niveau van de kandidaat
                </li>
              </ol>
            </p>
            <p>
              Aan de hand van onze feedback kunt u bepalen of u door wilt gaan
              met de plaatsing van uw kandidaat of wellicht dat een andere
              vacature beter bij het niveau van uw kandidaat past. Of u wilt
              enkel zekerheid alvorens het plaatsen bij uw high-profile klant,
              door een CodeReview uit te laten voeren heeft u net wat meer
              zekerheid.
            </p>
            <Link to="/pricing" className="btn btn-outline-indigo">
              Zie prijzen
            </Link>
          </div>
        </div>
        {/* az-column-signup-left */}
        <div className="az-column-signup">
          <h1 className="az-logo">
            <Link to="/">niveaubepaling.nl</Link>
          </h1>
          <div className="az-signup-header">
            <h2>Account aanmaken</h2>
            <h4>
              Het aanmaken van een account is gratis en kost ongeveer 1 minuut
            </h4>

            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Voornaam &amp; Achternaam</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Vul uw voor- en achternaam in"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              {/* form-group */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="Vul uw e-mailadres in"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              {/* form-group */}
              <div className="form-group">
                <label htmlFor="password">Wachtwoord</label>
                <input
                  id="password"
                  value={password}
                  type="password"
                  className="form-control"
                  placeholder="Vul uw wachtwoord in"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              {/* form-group */}
              <div className="form-group">
                <label htmlFor="phone_number">Telefoonnummer</label>
                <input
                  id="phone_number"
                  type="text"
                  className="form-control"
                  placeholder="Vul uw telefoonnummer in"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
              </div>
              {/* form-group */}
              <Button type="submit" className="btn btn-az-primary btn-block">
                Account aanmaken
              </Button>
            </form>
          </div>
          {/* az-signup-header */}
          <div className="az-signup-footer">
            <p>
              Heeft u al een account?{' '}
              <Link to="/login">Klik hier om in te loggen</Link>
            </p>
          </div>
          {/* az-signin-footer */}
        </div>
        {/* az-column-signup */}
      </div>
      {/* az-signup-wrapper */}
    </div>
  );
};

export default Signup;
