import React from 'react';
import { Link } from 'react-router-dom';
import HeaderLink from '../components/HeaderLink';
import { useAuth } from '../hooks/use-auth';
import { FEATURE_INVOICE, FEATURE_PROFILE } from '../feature-toggles';

function toggleHeaderMenu(e) {
  e.preventDefault();
  document.querySelector('body').classList.toggle('az-header-menu-show');
}

// TODO closeMenu call was removed, search git and get it back
function closeMenu(e) {
  e.target.closest('.dropdown').classList.remove('show');
  e.target.closest('.dropdown .dropdown-menu').classList.remove('show');
}

function Header() {
  const auth = useAuth();
  document.querySelector('body').classList.remove('az-header-menu-show');

  // eslint-disable-next-line react/destructuring-assignment
  const isAuthenticated = auth.isAuthenticated();
  return (
    <div className="az-header">
      <div className="container">
        <div className="az-header-left">
          <Link to="/" className="az-logo">
            <span /> niveaubepaling.nl
          </Link>
          <a
            id="azMenuShow"
            onClick={(event) => toggleHeaderMenu(event)}
            className="az-header-menu-icon d-lg-none"
            href="#/"
          >
            <span />
          </a>
        </div>
        <div className="az-header-menu">
          <div className="az-header-menu-header">
            <Link to="/" className="az-logo">
              <span /> niveaubepaling.nl
            </Link>
            <a
              href="#/"
              onClick={(event) => toggleHeaderMenu(event)}
              className="close"
            >
              &times;
            </a>
          </div>
          <ul className="nav">
            {/* <HeaderLink
                  to="/dashboard"
                  typcnId="typcn-chart-area-outline"
                  hideCondition={!isAuthenticated}
                >
                  Dashboard
                </HeaderLink> */}
            <HeaderLink to="/" typcnId="typcn-home-outline">
              Home
            </HeaderLink>
            <HeaderLink to="/pricing" typcnId="typcn-credit-card">
              Prijzen
            </HeaderLink>
            <HeaderLink
              to="/code-reviews"
              typcnId="typcn-chart-bar-outline"
              hideCondition={!isAuthenticated}
            >
              Code Reviews
            </HeaderLink>
            <HeaderLink
              to="/invoices"
              typcnId="typcn-document"
              hideCondition={!isAuthenticated || !FEATURE_INVOICE}
            >
              Facturen
            </HeaderLink>
          </ul>
        </div>
        <div className="az-header-right">
          <ul className="nav">
            <HeaderLink
              to="/profile"
              typcnId="typcn-user-outline"
              className="nav-link az-header-right-link"
              hideCondition={!isAuthenticated || !FEATURE_PROFILE}
            >
              {auth.userFullname}
            </HeaderLink>
            <HeaderLink
              to="/logout"
              typcnId="typcn-power-outline"
              className="nav-link az-header-right-link"
              hideCondition={!isAuthenticated}
            >
              Uitloggen
            </HeaderLink>
            <HeaderLink
              to="/register"
              typcnId="typcn-edit"
              className="nav-link az-header-right-link"
              hideCondition={isAuthenticated}
            >
              Registreren
            </HeaderLink>
            <HeaderLink
              to="/login"
              typcnId="typcn-key-outline"
              className="nav-link az-header-right-link"
              hideCondition={isAuthenticated}
            >
              Inloggen
            </HeaderLink>
          </ul>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};

export default Header;
