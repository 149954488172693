import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, Outlet } from 'react-router';
import { Button } from 'react-bootstrap';
import CodeReviewApi from '../Api';
import { useAuth } from '../hooks/use-auth';
import ViewCompanyData from '../components/ViewCompanyData';
import RegisterCompanyForm from '../components/RegisterCompanyForm';
import { useNotification } from '../hooks/notification-context';
import RequestCompanyAccess from '../components/RequestCompanyAccess';
import AccessRequested from './AccessRequested';
import useDebounce from '../hooks/debounce';

const RegisterCompany = () => {
  const [userKvkNumber, setUserKvkNumber] = useState('');
  const [userCompanyName, setUserCompanyName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [companyData, setCompanyData] = useState({});
  const auth = useAuth();
  const navigate = useNavigate();
  // const { addNotification } = useNotification();
  const debouncedKvkNumber = useDebounce(userKvkNumber, 500);

  useEffect(() => {
    const asyncDo = async () => {
      const email = await auth.getUserAttribute('email');
      setUserEmail(email);
    };

    asyncDo();
  }, [auth]);

  useEffect(() => {
    const asyncDo = async () => {
      if (debouncedKvkNumber && userCompanyName.length === 0) {
        if (debouncedKvkNumber.length === 8) {
          const api = new CodeReviewApi(await auth.getJwtToken());
          try {
            console.log(debouncedKvkNumber);
            const responseData = await api.getKvkCompany(debouncedKvkNumber);
            console.log(responseData);
            setCompanyData({ ...companyData, ...responseData });
            if (userCompanyName.length === 0) {
              setUserCompanyName(responseData.handelsnaam);
            }
          } catch (err) {
            setCompanyData({});
            // addNotification(`Fout bij ophalen KVK data`, 'error');
            // kvk api lookup is used to autocomplete/hint company name
            // now that this is complementaty this comment is non essential, if it fails its up to the user to supply company name
          }
        } else {
          setCompanyData({});
        }
      }
    };

    asyncDo();
  }, [debouncedKvkNumber]);

  const chooseCompany = async (event) => {
    event.preventDefault();

    const api = new CodeReviewApi(await auth.getJwtToken());
    const registeredCompanyData = await api.getRegisteredCompanyByKvk(
      userKvkNumber,
    );
    if (registeredCompanyData.has_company === true) {
      // go to attach to company flow
      navigate(
        `/register-company/request-access/${registeredCompanyData.company_id}`,
      );
    } else {
      // go to register company flow
      navigate('/register-company/new');
    }
  };

  const registerCompany = async ({ invoiceEmailAddress, name, kvkNumber }) => {
    const api = new CodeReviewApi(await auth.getJwtToken());
    const res = await api.registerCompany({
      invoiceEmailAddress,
      name,
      kvkNumber,
    });
    // after registering the company, the company-id cognito attribute has been set, so refresh session to reflect this change
    await auth.refreshSession();
    return res;
  };

  const requestAccess = async (companyId) => {
    const api = new CodeReviewApi(await auth.getJwtToken());
    const res = await api.requestAccessToCompany(companyId);
    console.log(res);
    // TODO if user log's in again while approval pending, show him that without giving other options
    navigate('/register-company/access-requested');
    return {
      emailAddress: res.emailAddress,
      companyName: res.companyName,
      accessRequestSucces: res.access_request_success,
    };
  };

  console.log('companyData', companyData);

  return (
    <div className="container">
      <Routes>
        <Route
          path=""
          exact
          element={
            <>
              <h2>Organisatie registreren</h2>
              <div className="row">
                <p className="col-md-6 col-sm-12">
                  Voordat u een codereview kunt aanvragen dient u eerst uw
                  organisatie te registreren op basis van uw KVK-nummer. Een bedrijfsnaam i.c.m. een
                  KVK-nummer kan slechts een keer geregistreerd worden zodat er
                  geen dubbele administatie onstaat en om fraude te voorkomen.
                </p>
                <p className="col-md-6 col-sm-12">
                  Indien u een recruiter bent en het bedrijf waarvoor u
                  codereviews gaat aanvragen al is geregistreerd vul ook dan
                  gewoon het kvk nummer in, in dit geval wordt er een
                  koppelverzoek gestuurd naar de contactpersoon die de
                  inschrijving voor dit bedrijf beheert.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <form onSubmit={chooseCompany}>
                  <div className="form-group">
                    <label
                      htmlFor="user_kvk_number"
                      title="Vul uw KVK-nummer in"
                    >
                      KVK-nummer
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_kvk_number"
                      placeholder="Vul uw KVK-nummer in"
                      value={userKvkNumber}
                      onChange={(event) => {
                        setUserKvkNumber(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="user_company_name"
                      title="Vul uw bedrijfsnaam in"
                    >
                      Bedrijfsnaam
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_company_name"
                      placeholder="Bedrijfsnaam"
                      value={userCompanyName}
                      onChange={(event) =>
                        setUserCompanyName(event.target.value)
                      }
                    />
                  </div>
                  {/* {Object.keys(companyData).length > 0 && ( */}
                  {/*     <ViewCompanyData */}
                  {/*         dossiernummer={companyData.dossiernummer} */}
                  {/*         handelsnaam={companyData.handelsnaam} */}
                  {/*         plaats={companyData.plaats} */}
                  {/*         yesCallback={chooseCompany} */}
                  {/*         noCallback={() => { */}
                  {/*             setCompanyData({}); */}
                  {/*             setUserKvkNumber(''); */}
                  {/*         }} */}
                  {/*     /> */}
                  {/* )} */}
                  <div className="row">
                    <div className="col-6">
                      {/* form-group */}
                      <Button
                        className="btn btn-block btn-az-primary"
                        onClick={() => {navigate('/choose-organization')}}
                      >
                        Vorige stap
                      </Button>
                    </div>
                    <div className="col-6">
                      {/* form-group */}
                      <Button
                        type="submit"
                        className="btn btn-block btn-az-primary"
                      >
                        Volgende stap
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          }
        />
        <Route
          path="new"
          element={
            <RegisterCompanyForm
              kvkNumber={companyData.dossiernummer}
              handelsnaam={companyData.handelsnaam}
              plaats={companyData.plaats}
              userEmail={userEmail}
              registerCompanyCallback={registerCompany}
            />
          }
        />
        <Route
          path="request-access/:companyId"
          element={
            <RequestCompanyAccess
              requestAccessCallback={requestAccess}
              handelsnaam={companyData.handelsnaam}
            />
          }
        />
        <Route
          path="access-requested"
          element={<AccessRequested handelsnaam={companyData.handelsnaam} />}
        />
      </Routes>
      <Outlet />
    </div>
  );
};

export default RegisterCompany;
