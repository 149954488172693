import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import isPathActive from '../utilities/routePathUtil';

const HeaderLink = ({ to, typcnId, children, hideCondition, className }) => {
  if (hideCondition) {
    return null;
  }

  const location = useLocation();

  return (
    <li className={isPathActive(location, to) ? 'nav-item active' : 'nav-item'}>
      <Link to={to} className={className}>
        <i className={`typcn ${typcnId}`} />
        {children}
      </Link>
    </li>
  );
};

HeaderLink.defaultProps = {
  typcnId: 'typcn-media-record',
  hideCondition: false,
  className: 'nav-link',
};

HeaderLink.propTypes = {
  to: PropTypes.string.isRequired,
  typcnId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  hideCondition: PropTypes.bool,
  className: PropTypes.string,
};

export default HeaderLink;
