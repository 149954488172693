import { Button } from 'react-bootstrap';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CodeReviewApi from '../Api';
import { useAuth } from '../hooks/use-auth';
import StatusBadge from '../components/StatusBadge';
import EditReviewFeedback from './EditReviewFeedback';
import Loading from '../components/Loading';

const ViewCodereview = () => {
  const auth = useAuth();
  // TODO: redirect if not authed

  const { codereviewId } = useParams();
  const [review, setReview] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reviewing, setReviewing] = useState(false);
  const [role, setRole] = useState('recruiter');

  const isReviewer = role === 'admin';

  useEffect(() => {
    const asyncDo = async () => {
      setLoading(true);
      const api = new CodeReviewApi(await auth.getJwtToken());
      const fetchedRole = await auth.getUserAttribute(
        'custom:role',
        'recruiter',
      );
      setRole(fetchedRole);
      const fetchedReview = await api.getCodeReview(codereviewId);
      console.log(fetchedReview);
      setReview(fetchedReview);
      setReviewing(fetchedReview.status === 'reviewing');
      setLoading(false);
      // TODO: add error handling
    };

    asyncDo();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const startCodeReview = async () => {
    setReviewing(true);
    const api = new CodeReviewApi(await auth.getJwtToken());
    // TODO: how to share api instance between useEffect and other closures?
    await api.updateCodeReview(codereviewId, { status: 'reviewing' });
    // TODO: add error handling
    setReview({ ...review, status: 'reviewing' });
  };

  const updateStatus = async (status) => {
    setReviewing(false);
    const api = new CodeReviewApi(await auth.getJwtToken());
    // TODO: how to share api instance between useEffect and other closures?
    await api.updateCodeReview(codereviewId, { status });
    // TODO: add error handling
    setReview({ ...review, status });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          <div className="card bg-gray-100 bd-0">
            <div className="card-body">
              <h5 className="card-title">Aanvraag</h5>
              <dl className="row">
                <dt className="col-sm-4">ID</dt>
                <dd className="col-sm-8">{review.id}</dd>
                <dt className="col-sm-4">Aangevraagd op</dt>
                <dd className="col-sm-8">
                  {DateTime.fromISO(review.requestedAt).toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS,
                  )}
                </dd>
                <dt className="col-sm-4">Aangevraagd door</dt>
                <dd className="col-sm-8" title={review.userId}>
                  {review.user.name}
                </dd>
                <dt className="col-sm-4">E-mail aanvrager</dt>
                <dd className="col-sm-8" title={review.userId}>
                  {review.user.email}
                </dd>
                <dt className="col-sm-4">CodeReview Status</dt>
                <dd className="col-sm-8">
                  <StatusBadge status={review.status} />
                </dd>
                <dt className="col-sm-4">CodeReview type</dt>
                <dd className="col-sm-8">
                  {review.reviewTypeNameArchived} à €
                  {review.reviewTypePriceArchived} excl. BTW.
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="card bg-gray-200 bd-0">
            <div className="card-body">
              <h5 className="card-title">Kandidaat</h5>

              <dl className="row">
                <dd className="col-sm">
                  <dl className="row">
                    <dt className="col-sm-4">Voornaam</dt>
                    <dd className="col-sm-8">{review.candidate.firstname}</dd>
                    <dt className="col-sm-4">Achternaam</dt>
                    <dd className="col-sm-8">{review.candidate.lastname}</dd>
                    <dt className="col-sm-4">E-mailadres</dt>
                    <dd className="col-sm-8">
                      {review.candidate.emailAddress}
                    </dd>
                  </dl>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <hr className="mg-y-30" />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">CodeReview</h5>
              <dl className="row">
                <dt className="col-sm-3">Git Repository</dt>
                <dd className="col-sm-9">
                  {review.repository1 && (
                    <a
                      href={review.repository1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {review.repository1}
                    </a>
                  )}
                </dd>
                {review.repository2 && (
                  <>
                    <dt className="col-sm-3">Secundaire Git Repository</dt>
                    <dd className="col-sm-9">
                      {review.repository2 && (
                        <a
                          href={review.repository2}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {review.repository2}
                        </a>
                      )}
                    </dd>
                  </>
                )}
                <dt className="col-sm-3">Zipfile</dt>
                <dd className="col-sm-9">
                  {review.zipfile1 && (
                    <a href={review.zipfile1Url}>{review.zipfile1}</a>
                  )}
                </dd>
                <dt className="col-sm-3">Review feedback</dt>
                <dd className="col-sm-9">
                  <EditReviewFeedback
                    codereview={review}
                    setCodereview={setReview}
                    reviewing={reviewing}
                  />
                </dd>
              </dl>

              {isReviewer && (
                <div>
                  <p>Acties:</p>
                  {review.status === 'open' && (
                    <Button className="card-link" onClick={startCodeReview}>
                      Start Code Review
                    </Button>
                  )}
                  {review.status === 'reviewing' && (
                    <Button
                      className="card-link"
                      onClick={() => updateStatus('feedback_provided')}
                    >
                      Code Review afronden
                    </Button>
                  )}
                  {review.status !== 'rejected' && (
                    <Button
                      className="card-link btn btn-danger"
                      onClick={() => updateStatus('rejected')}
                    >
                      Code Review afwijzen
                    </Button>
                  )}
                  {review.status === 'rejected' && (
                    <Button
                      className="card-link danger"
                      onClick={() => updateStatus('open')}
                    >
                      Code Review her-openen
                    </Button>
                  )}
                  {review.status === 'feedback_provided' && (
                    <Button
                      className="card-link danger"
                      onClick={() => updateStatus('invoiced')}
                    >
                      Code Review is gefactureerd
                    </Button>
                  )}
                </div>
              )}

              {/* <pre>{JSON.stringify(review)}</pre> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCodereview;
