import React, { useState, useCallback, useContext } from 'react';

export const NotificationContext = React.createContext({
  notification: null,
  addNotification: () => {},
  removeNotification: () => {},
});

export const useNotification = () => {
  const { notification, addNotification, removeNotification } =
    useContext(NotificationContext);
  return { notification, addNotification, removeNotification };
};

// eslint-disable-next-line react/prop-types
export default function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const removeNotification = () => setNotification(null);

  const addNotification = (message, status) => {
    if (status === undefined) {
      status = 'info';
    }
    setNotification({ message, status });
  };

  const contextValue = {
    notification,
    addNotification: useCallback(
      (message, status) => addNotification(message, status),
      [],
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
