import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

const Logout = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated()) {
      auth.signout();
    }
  });

  return <Navigate to="/" />;
};

export default Logout;
