// https://stackoverflow.com/questions/38110615/how-to-allow-my-user-to-reset-their-password-on-cognito-user-pools

/* eslint-disable jsx-a11y/label-has-associated-control */
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import UserPool from '../UserPool';
import { useNotification } from '../hooks/notification-context';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const { addNotification } = useNotification();

  const userData = {
    Username: email,
    Pool: UserPool,
  };
  const cognitoUser = new CognitoUser(userData);

  const initiatePasswordChange = (event) => {
    event.preventDefault();

    cognitoUser.forgotPassword({
      onSuccess(result) {
        console.log(`call result: ${result}`);
        // {"CodeDeliveryDetails":{"AttributeName":"email","DeliveryMedium":"EMAIL","Destination":"d***@g***.com"}
        const message = `Een verificatiecode is per e-mail verstuurd naar: ${result.CodeDeliveryDetails.Destination}`;
        addNotification(message);
      },
      onFailure(err) {
        addNotification(JSON.stringify(err));
      },
      // inputVerificationCode() {
      //   const verificationCode = prompt('Please input verification code ', '');
      //   const newPassword = prompt('Enter new password ', '');
      //   ;
      // },
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    cognitoUser.confirmPassword(validationCode, newPassword, this);
  };

  return (
    <div className="container">
      <h2>Wachtwoord vergeten</h2>
      <div className="row">
        <p className="col-md-6 col-sm-12">
          Om uw registratie te bevestigen voert u de verificatiecode in. Deze
          verificatiecode is verstuurd naar het e-mailadres dat u bij het
          registreren heeft opgegeven.
        </p>

        <div className="col-md-6 col-sm-12">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">E-mailadres</label>
              <input
                type="text"
                id="email"
                className="form-control"
                placeholder="Vul uw voor- en achternaam in"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <Button
                  type="button"
                  className="btn btn-block btn-az-secondary"
                  onClick={initiatePasswordChange}
                >
                  Wachtwoord wijzigen
                </Button>
              </div>
            </div>
            {/* form-group */}
            <div className="form-group">
              <label htmlFor="validation_code">Verificatiecode</label>
              <input
                type="text"
                className="form-control"
                id="validation_code"
                placeholder="Vul uw e-mailadres in"
                value={validationCode}
                onChange={(event) => setValidationCode(event.target.value)}
              />
            </div>
            {/* form-group */}
            <div className="form-group">
              <label htmlFor="validation_code">Nieuwe wachtwoord</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Vul uw nieuwe wachtwoord in"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </div>
            {/* form-group */}
            <div className="row">
              <div className="col-6">
                {/* form-group */}
                <Button type="submit" className="btn btn-block btn-az-primary">
                  Reset password
                </Button>
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  className="btn btn-block btn-az-secondary"
                  onClick={initiatePasswordChange}
                >
                  Verificatiecode opnieuw sturen
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
