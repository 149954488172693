import React, { useEffect, useState } from 'react';
import CodeReviewApi from '../Api';
import { useAuth } from '../hooks/use-auth';

const api = new CodeReviewApi();

function Profile() {
  const auth = useAuth();
  const [companies, setCompanies] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);

  useEffect(() => {
    const asyncDo = async () => {
      api.init(await auth.getJwtToken());
      let d1 = await api.getCompanies();
      let d2 = await auth.getUserAttributes();
      setUserAttributes(d2);
      console.log(d1);
      setCompanies(d1);
    };

    asyncDo();
  }, []);

  return (
    <>
      <div className="container az-content-app p-0">
        <div className="az-content">
          <div className="container">Profile Page</div>
          <pre>companies: {JSON.stringify(companies, null, 2)}</pre>
          <pre>userAttributes: {JSON.stringify(userAttributes, null, 2)}</pre>
        </div>
      </div>
    </>
  );
}

export default Profile;
