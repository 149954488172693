import React from 'react';

function Invoices() {
  return (
    <div className="container">
      <div>
        <h1>Facturen</h1>
        <p>Hier komen uw facturen.</p>
      </div>
    </div>
  );
}

export default Invoices;
