import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserPool from '../UserPool';
import Loading from '../components/Loading';

export const authContext = createContext();

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState(null);
    const [userAttributes, setUserAttributes] = useState([]);
    const [userFullname, setUserFullname] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const initUser = async () => {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
            let localUsername = cognitoUser.getUsername();
            console.log(`username: ${localUsername}`)
            setUsername(localUsername);
            await fetchUserFullname();
            await fetchUserEmail();
        } else {
            setUsername(null);
        }
    };

    const getUsername = () => {
        return username;
    }

    const fetchUserFullname = async () => {
        try {
            const name = await getUserAttribute('name');
            setUserFullname(name);
        } catch (err) {
            setUserFullname('');
        }
    };
    const fetchUserEmail = async () => {
        try {
            const email = await auth.getUserAttribute('email');
            setUserEmail(email);
        } catch (err) {
            setUserEmail('');
        }
    };

    const signin = (email, password) => {
        const userData = {
            Username: email,
            Pool: UserPool,
            Storage: UserPool.storage,
        };
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess(/* result */) {
                    setUsername(cognitoUser.getUsername());
                    fetchUserFullname()
                        .then(fetchUserEmail)
                        .then(() => {
                            resolve(cognitoUser.getUsername());
                        });
                },
                onFailure(err) {
                    setUsername(null);
                    reject(err);
                },
            });
        });
    };

    const getJwtToken = () => {
        return new Promise((resolve, reject) => {
            const currentUser = UserPool.getCurrentUser();
            currentUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                }
                resolve(session.getIdToken().getJwtToken());
            });
        });
    };

    const getUserAttributes = () => {
        return new Promise((resolve, reject) => {
            if (userAttributes.length > 0) {
                resolve(userAttributes);
                return;
            }
            const currentUser = getCurrentUser();
            currentUser.getSession((err /* , session */) => {
                if (err) {
                    console.error(err.message || JSON.stringify(err));
                    reject(err.message || JSON.stringify(err));
                }
                currentUser.getUserAttributes((err2, result) => {
                    if (err2) {
                        console.error(err.message || JSON.stringify(err));
                        reject(err.message || JSON.stringify(err));
                    }

                    setUserAttributes(result);
                    resolve(result);
                });
            });
        });
    };

    const refreshSession = () => {
        return new Promise((resolve, reject) => {
            const currentUser = UserPool.getCurrentUser();
            setUser(currentUser);
            currentUser.getSession((err, session) => {
                const refreshToken = session.getRefreshToken();
                currentUser.refreshSession(refreshToken, (err2, newSession) => {
                    if (err2) {
                        reject(err2);
                    } else {
                        console.log(`refreshed session: ${newSession}`);
                        setUserAttributes([]);
                        resolve(newSession);
                    }
                });
            });
        });
    };
    const getCurrentUser = () => {
        if (user !== null) {
            return user;
        }
        const currentUser = UserPool.getCurrentUser();
        setUser(currentUser);
        return currentUser;
    };

    const getUserAttribute = async (name, defaultValue) => {
        const currentUser = getCurrentUser();
        const localUserAttributes = await getUserAttributes();
        console.log('user attributes', localUserAttributes);
        const attribute = localUserAttributes.find(
            (attrib) => attrib.Name === name,
        );
        if (attribute === undefined) {
            if (defaultValue) {
                return defaultValue;
            }
            throw new Error(
                `attribute ${name} not found for username: ${currentUser.username}`,
            );
        }
        return attribute.Value;
    };

    const isAuthenticated = () => {
        return user !== null;
    };

    const signout = () => {
        setUsername(null);
        setUser(null);
        setUserFullname('');
        setUserAttributes([]);
        setUserEmail('');
        const currentUser = UserPool.getCurrentUser();
        if (currentUser !== null) {
            const userData = {
                Username: currentUser.getUsername(),
                Pool: UserPool,
                Storage: UserPool.storage,
            };
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.signOut();
        }
    };

    return {
        initUser,
        signin,
        signout,
        getUserAttributes,
        getUserAttribute,
        isAuthenticated,
        getJwtToken,
        getCurrentUser,
        refreshSession,
        userFullname,
        userEmail,
        getUsername,
    };
};

export const ProvideAuth = ({ children }) => {
    // eslint-disable-next-line no-use-before-define
    const [isLoading, setIsLoading] = useState(true);
    // Currently this ProvideAuth works because isLoading is initialized to be true
    // when I init it to false, the initUser finishes too late. I dont yet understand whats going on
    // but be aware that this thing is a workaround
    const auth = useProvideAuth();

    useEffect(() => {
        auth.initUser();
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

ProvideAuth.propTypes = {
    children: PropTypes.element.isRequired,
};
