import React from 'react';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RequestCompanyAccess = ({ requestAccessCallback, handelsnaam }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <h2>Account koppelen</h2>
      <p>
        Het bedrijf dat u opgezocht heeft is reeds geregistreerd. U gaat nu
        toegang vragen aan de contact persoon van dit bedrijf. Dit is meestal
        een collega binnen het bedrijf waar u voor werkt. Deze contact persoon
        ontvangt een e-mail waarin gevraagd wordt of het in orde is dat u
        gekoppeld wordt aan <em>{handelsnaam}</em>. Deze contact persoon krijgt
        uw naam, achternaam, e-mailadres en telefoonnummer te zien in de e-mail
        zodat zij/haar contact met u op kan nemen.
      </p>
      <Button
        className="btn-secondary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Terug
      </Button>
      <Button
        className="btn-primary"
        onClick={() => {
          requestAccessCallback(companyId);
        }}
      >
        Toegang Aanvragen
      </Button>
    </>
  );
};

export default RequestCompanyAccess;
