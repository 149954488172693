import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CodeReviewApi from '../Api';
import Loading from '../components/Loading';
import { useNotification } from '../hooks/notification-context';
import { useAuth } from '../hooks/use-auth';

const container = (x) => {
  return <div className="container">{x}</div>;
};

const ReviewAccess = () => {
  const { accessRequestId } = useParams();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [errorSituation, setErrorSituation] = useState(null);
  const [accessRequest, setAccessRequest] = useState({});
  const { addNotification } = useNotification();
  const [approvalStatus, setApprovalStatus] = useState(null);

  useEffect(() => {
    const asyncDo = async () => {
      setLoading(true);
      const api = new CodeReviewApi(await auth.getJwtToken());
      try {
        const accessRequestData = await api.getAccessRequest(accessRequestId);
        setAccessRequest(accessRequestData);
        if (accessRequestData.status !== 'requested') {
          setApprovalStatus('already-done');
        }
      } catch (err) {
        setErrorSituation(err.message);
      } finally {
        setLoading(false);
      }
    };

    asyncDo();
  }, []);

  if (errorSituation) {
    return container(errorSituation);
  }

  const updateAccessRequest = async (action) => {
    const api = new CodeReviewApi(await auth.getJwtToken());
    try {
      const accessRequestData = await api.updateAccessRequest(
        accessRequestId,
        action,
      );
      console.log(accessRequestData);
      setApprovalStatus('done');
    } catch (err) {
      addNotification(err.message);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (approvalStatus === 'done') {
    return container(<p>Toegangsverzoek is afgehandeld.</p>);
  }
  if (approvalStatus === 'already-done') {
    return container(<p>Toegangsverzoek is reeds afgehandeld.</p>);
  }

  return container(
    <>
      <h1>Toegangsverzoek</h1>
      <p>
        Gebruiker <em>{accessRequest.fullname}</em> met e-mailadres{' '}
        <em>{accessRequest.emailAddress}</em> wilt gekoppeld worden aan uw
        bedrijf. Wilt u dit verzoek goedkeuren of afkeuren?
      </p>
      <Button
        variant="success"
        onClick={() => {
          updateAccessRequest('approve');
        }}
      >
        Goedkeuren
      </Button>{' '}
      <Button
        variant="danger"
        onClick={() => {
          updateAccessRequest('reject');
        }}
      >
        Afkeuren
      </Button>
    </>,
  );
};

export default ReviewAccess;
