/* eslint-disable jsx-a11y/label-has-associated-control */
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import UserPool from '../UserPool';
import { useNotification } from '../hooks/notification-context';

const SignupVerification = () => {
  const { emailAddress } = useParams();
  const emailPrefilled = !!emailAddress;
  const [email, setEmail] = useState(emailAddress || '');
  const [success, setSuccess] = useState(false);
  const [validationCode, setValidationCode] = useState('');

  const { addNotification } = useNotification();

  const resendConfirmation = (event) => {
    event.preventDefault();
    const userData = {
      Username: email,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        addNotification(err.message || JSON.stringify(err), 'error');
        return;
      }
      console.log(`call result: ${result}`);
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const userData = {
      Username: email,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(validationCode, true, (err, result) => {
      if (err) {
        // verificatie error
        if (
          err.message &&
          err.message.includes('Current status is CONFIRMED')
        ) {
          addNotification('Gebruiker is reeds geverifieerd', 'info');
        } else {
          // TODO: verify if this error is really user friendly
          addNotification(err.message || JSON.stringify(err), 'error');
        }
        return;
      }

      // verificatie succesvol
      addNotification('Gebruiker is succesvol geverifieerd', 'success');
      console.log(`call result: ${result}`);
      setSuccess(true);
    });
  };

  if (success) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <h2>Registratie verificatie</h2>
      <div className="row">
        <p className="col-md-6 col-sm-12">
          Om uw registratie te bevestigen voert u de verificatiecode in. Deze
          verificatiecode is verstuurd naar het e-mailadres dat u bij het
          registreren heeft opgegeven.
        </p>

        <div className="col-md-6 col-sm-12">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">E-mailadres</label>
              <input
                type="text"
                id="email"
                disabled={emailPrefilled}
                className="form-control"
                placeholder="Vul uw voor- en achternaam in"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            {/* form-group */}
            <div className="form-group">
              <label htmlFor="validation_code">Verificatiecode</label>
              <input
                type="text"
                className="form-control"
                id="validation_code"
                placeholder="Vul uw verificatiecode in"
                value={validationCode}
                onChange={(event) => setValidationCode(event.target.value)}
              />
            </div>
            {/* end form-group */}
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Button
                  type="button"
                  className="btn btn-block btn-az-secondary"
                  onClick={resendConfirmation}
                >
                  Verificatiecode opnieuw sturen
                </Button>
              </div>
              <div className="col-md-6 col-sm-12">
                <Button type="submit" className="btn btn-block btn-az-primary">
                  Valideren
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupVerification;
