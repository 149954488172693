import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import { isChrome } from 'react-device-detect';

const poolData = {
  UserPoolId: 'eu-west-2_WGx6meDp0',
  ClientId: '6mk0f44252rtd13psso1bvcs6',
};

// For chrome, setting a cookie for domain localhost does not work
// so then we do not set the cookiestorage, but let the UserPool figure out
// what storage it wants to use this only kicks in when COOKIE_HOST includes
// localhost, so for production it will use cookieStorage anyways
if (process.env.REACT_APP_COOKIE_HOST.includes('localhost') && !isChrome) {
  if (!process.env.REACT_APP_COOKIE_HOST) {
    throw new Error(
      'Env var REACT_APP_COOKIE_HOST must be set to your target domainname for the CookieStorage to properly work',
    );
  }

  poolData.Storage = new CookieStorage({
    domain: process.env.REACT_APP_COOKIE_HOST,
    secure: true,
  });
}

export default new CognitoUserPool(poolData);
