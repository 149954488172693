import React, { useState } from 'react';
import CodeReviewApi from '../Api';
import { useNotification } from '../hooks/notification-context';

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const { addNotification } = useNotification();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const api = new CodeReviewApi();
      let response = await api.postContact(formData);
      if (response.status !== 200) {
        throw new Error(
          '😭 Door een onverwachte fout is het bericht niet verzonden. Stuur een email naar info@niveaubepaling.nl',
        );
      }
      const successMsg =
        '✅ Bedankt voor uw bericht. We nemen zo snel mogelijk contact met u op.';
      addNotification(successMsg);
      setStatus(successMsg);
      setFormData({ email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      const errorMsg = 'Failed to send message. Please try again.';
      addNotification(errorMsg, 'error');
      setStatus(errorMsg);
    }
  };

  return (
    <section id="contact" className="bg-white dark:bg-gray-900">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Neem contact op
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          Als u wilt weten hoe Niveaubepaling.nl u kan helpen bij het vinden van
          de beste IT-talenten, neem dan gerust contact met ons op. Wij staan
          klaar om u te helpen bij het optimaliseren van uw recruitmentproces.
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Uw email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="naam@bedrijf.nl"
              value={formData.email}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Onderwerp
            </label>
            <input
              type="text"
              id="subject"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Laat ons weten hoe we u kunnen helpen"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Uw bericht
            </label>
            <textarea
              id="message"
              rows="6"
              value={formData.message}
              name="message"
              onChange={handleChange}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Laat een bericht achter..."
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Verstuur bericht
          </button>
        </form>
        {status && (
          <p className="mt-4 text-sm text-center text-gray-900 dark:text-gray-900 p-3 bg-blue-100 rounded-lg">
            {status}
          </p>
        )}
      </div>
    </section>
  );
};

export default Contact;
