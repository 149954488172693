/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DateTime, Settings } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import CodeReviewApi from '../Api';
import { useAuth } from '../hooks/use-auth';
import CodeReviewForm from './CodeReviewForm';
import ViewCodereview from './ViewCodereview';
import StatusBadge from '../components/StatusBadge';
import CodeReviewId from '../components/CodeReviewId';
import Loading from '../components/Loading';
import { DEFAULT_REDIRECT_NO_ORGANIZATION, DEFAULT_REDIRECT_REVIEW_ACCESS_STATUS } from '../constants';

Settings.defaultLocale = 'nl';

// wrap currently does not do anything in this configuration
// button: true compresses the width, not sure why, but not needed at this moment
// FYI: button: true sends the events when pressing only to the custom element instead of onto the table as well
const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    // wrap: false,
    // button: true,
    grow: 1,
    cell: (row) => (
      <CodeReviewId companyId={row.companyId} codeReviewId={row.id} />
    ),
  },
  {
    name: 'Kandidaat',
    sortable: true,
    // right: true,
    cell: (row) =>
      `${row.candidate.firstname || ''} ${row.candidate.lastname || ''}`,
  },
  {
    name: 'Aangevraagd op',
    selector: 'requestedAt',
    sortable: true,
    // right: true,
    cell: (row) =>
      DateTime.fromISO(row.requestedAt).toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS,
      ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: (row) => <StatusBadge status={row.status} />,
  },
];

const CodeReviews = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const asyncDo = async () => {
      setLoading(true);
      let companyId;
      const api = new CodeReviewApi(await auth.getJwtToken());
      try {
        companyId = await auth.getUserAttribute('custom:company-id');
      } catch (err) {
        // TODO: check review-access api of er een request is, zo niet redirect dan naar register-company
        let hasAccessRequest = await api.hasAccessRequest(companyId, auth.getUsername());
        console.log(hasAccessRequest);
        if (hasAccessRequest) {
          navigate(DEFAULT_REDIRECT_REVIEW_ACCESS_STATUS);
        } else {
          navigate(DEFAULT_REDIRECT_NO_ORGANIZATION);
        }
        setLoading(false);
        return;
      }

      const fetchedReviews = await api.getCodeReviews(companyId);
      setReviews(fetchedReviews);
      setLoading(false);
    };

    asyncDo();
  }, []);

  return (
    <div className="container az-content-app p-0">
      <div className="az-content">
        <Routes>
          <Route
            path=""
            element={
              <DataTable
                data={reviews}
                columns={columns}
                progressPending={loading}
                persistTableHead
                title="Code Reviews"
                progressComponent={<Loading />}
                noDataComponent={
                  <p>
                    Er zijn nog geen codereviews aangevraagd, druk op Code
                    Review Aanvragen.
                  </p>
                }
                actions={
                  <Link to="new">
                    <Button variant="primary btn-block" className="btn-rounded">
                      Code Review Aanvragen
                    </Button>
                  </Link>
                }
              />
            }
          />
          <Route
            path="new/:preselectedReviewTypeId"
            element={<CodeReviewForm setReviewsCallback={setReviews} />}
          />
          <Route path=":companyId/:codereviewId" element={<ViewCodereview />} />
          <Route
            path="new"
            element={<CodeReviewForm setReviewsCallback={setReviews} />}
          />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export default CodeReviews;
