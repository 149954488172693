import axios from 'axios';

const API_BASE =
  process.env.REACT_APP_API_BASE_URL ||
  'https://8rk1s4uh67.execute-api.eu-west-2.amazonaws.com/api/';
// const API_BASE = 'http://127.0.0.1:8000/';

class CodeReviewApi {
  constructor(jwtToken) {
    this.init(jwtToken);
  }

  async init(jwtToken) {
    let headers = {};
    if (jwtToken) {
      headers = { Authorization: jwtToken };
    }
    this.client = axios.create({
      baseURL: API_BASE,
      headers: headers,
      validateStatus(status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
  }

  async getCodeReviews(companyId) {
    const response = await this.client.get(`/codereviews/${companyId}`);
    CodeReviewApi.errorHandler(response);
    return response.data.data.codereviews;
  }

  async getCompanies() {
    const response = await this.client.get(`/companies`);
    CodeReviewApi.errorHandler(response);
    return response.data;
  }

  async getCodeReview(codereviewId) {
    const response = await this.client.get(`/codereview/${codereviewId}`);
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async introspect() {
    const response = await this.client.get('/introspect');
    CodeReviewApi.errorHandler(response);
    return response.data;
  }

  async getCodeReviewTypes() {
    const response = await this.client.get(`/codereview-types`);
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async getAccessRequest(accessRequestId) {
    const response = await this.client.get(
      `/access-request/${accessRequestId}`,
    );
    CodeReviewApi.errorHandler(response);
    return response.data.data.accessrequest;
  }

  async createCodeReview(data) {
    const response = await this.client.post('/codereview', data);
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async updateCodeReview(codereviewId, data) {
    const response = await this.client.patch(
      `/codereview/${codereviewId}`,
      data,
    );
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async getKvkCompany(userKvkNumber) {
    const response = await this.client.get(`/kvk-company/${userKvkNumber}`);
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async getRegisteredCompanyByKvk(userKvkNumber) {
    const response = await this.client.get(
      `/get-company-by-kvk/${userKvkNumber}`,
    );
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async registerCompany({ invoiceEmailAddress, name, kvkNumber }) {
    const response = await this.client.post('/register-company', {
      invoiceEmailAddress,
      name,
      kvkNumber,
    });
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async requestAccessToCompany(companyId) {
    const response = await this.client.post('/request-access', {
      companyId,
    });
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async updateAccessRequest(accessRequestId, action) {
    const response = await this.client.post(
      `/review-access/${accessRequestId}/${action}`,
    );
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async getUploadUrl(objectName, contentType) {
    const response = await this.client.get(`/s3/sign`, {
      params: { objectName, contentType },
    });
    return response.data.data;
  }

  async hasAccessRequest(companyId, userId) {
    const response = await this.client.get(
      `/has-access-request/${companyId}/${userId}`,
    );
    return response.data.data.has_access_request;
  }

  async registerPersonalCompany() {
    const response = await this.client.post('/register-personal-company');
    CodeReviewApi.errorHandler(response);
    return response.data.data;
  }

  async postContact({ email, subject, message }) {
    const response = await this.client.post('/contact', {
      email,
      subject,
      message,
    });
    CodeReviewApi.errorHandler(response);
    return response;
    // return response.data.message;
  }

  static errorHandler(response) {
    if (response.status >= 400 && response.status < 500) {
      const err = new Error(response.data.Message);
      err.name = response.data.Code;
      err.message = response.data.Message;
      throw err;
    }
  }
}

export default CodeReviewApi;
