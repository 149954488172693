import React, { createContext, useState, useContext, useEffect } from 'react';

const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
  const [consentGiven, setConsentGiven] = useState(false);

  const giveConsent = (consentValue) => {
    setConsentGiven(true);
    localStorage.setItem('cookieConsent', JSON.stringify(consentValue));
  };

  const checkConsent = () => {
    let cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent !== null) {
      setConsentGiven(cookieConsent.length > 0);
    }
  };

  useEffect(() => {
    checkConsent();
  }, []);

  return (
    <CookieConsentContext.Provider value={{ consentGiven, giveConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  return useContext(CookieConsentContext);
};
