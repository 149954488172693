/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import ReactS3Uploader from 'react-s3-uploader';
import validator from 'validator';
import CodeReviewApi from '../Api';
import Loading from '../components/Loading';
import { useAuth } from '../hooks/use-auth';

const api = new CodeReviewApi();

const CodeReviewForm = ({ setReviewsCallback }) => {
  const auth = useAuth();
  const { preselectedReviewTypeId } = useParams();

  const defaultValues = {};
  if (preselectedReviewTypeId !== undefined) {
    defaultValues.reviewTypeId = preselectedReviewTypeId;
  }

  const [loading, setLoading] = useState(true);
  const [reviewTypes, setReviewTypes] = useState([]);
  const [reviewId, setReviewId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [uploadLabel, setUploadLabel] = useState('Zipfile');

  useEffect(() => {
    const asyncDo = async () => {
      api.init(await auth.getJwtToken());
      setLoading(true);
      const fetchedReviewTypes = await api.getCodeReviewTypes();
      setReviewTypes(fetchedReviewTypes);
      setLoading(false);
    };

    asyncDo();
  }, []);

  const {
    // control,
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, isSubmitting, isSubmitSuccessful },
    reset,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const onSubmit = async (data) => {
    const responseData = await api.createCodeReview({
      ...data,
    });
    setReviewsCallback((reviews) => [responseData, ...reviews]);
    setReviewId(responseData.id);
    setCompanyId(responseData.companyId);
  };

  const doReset = () => {
    reset();
  };

  // console.log('touchedFields', touchedFields);
  // console.log('watch', watch());
  console.log('errors', errors);
  const repository1 = watch('repository1');
  const zipfile1 = watch('zipfile1');

  if (isSubmitSuccessful) {
    return (
      <div>
        <h3>Uw CodeReview is aangevraagd</h3>
        <p>
          Uw aanvraag wordt binnen de aangevraagde tijd in behandeling genomen.
          Per e-mail ontvangt u een notificatie zodra de niveaubepaling gedaan
          is.
        </p>
        <p>
          <Link to={`/code-reviews/${companyId}/${reviewId}`}>
            Klik hier om uw code review aanvraag te bekijken
          </Link>
        </p>
        <p>
          <Button type="button" onClick={doReset}>
            Of dien een nieuwe aanvraag in
          </Button>
        </p>
        <p>
          <Link to="/code-reviews">Of ga naar overzicht</Link>
        </p>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isSubmitting}>
        <Form.Row>
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>Email adres</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mailadres kandidaat"
              {...register('candidate.emailAddress', {
                validate: (value) =>
                  validator.isEmail(value) || 'Vul een valide e-mailadres in',
              })}
              isInvalid={
                touchedFields.candidate?.emailAddress &&
                errors.candidate?.emailAddress
              }
              isValid={
                touchedFields.candidate?.emailAddress &&
                errors.candidate?.emailAddress === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.candidate?.emailAddress?.message}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              We&apos;ll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>Voornaam</Form.Label>
            <Form.Control
              type="text"
              placeholder="Voornaam kandidaat"
              {...register('candidate.firstname')}
              isInvalid={
                touchedFields.candidate?.firstname &&
                errors.candidate?.firstname
              }
              isValid={
                touchedFields.candidate?.firstname &&
                errors.candidate?.firstname === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.candidate?.firstname?.message}
            </Form.Control.Feedback>
            {/* <Form.Text className="text-muted">
              We&apos;ll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>Achternaam</Form.Label>
            <Form.Control
              type="text"
              placeholder="Achternaam kandidaat"
              {...register('candidate.lastname')}
              isInvalid={
                touchedFields.candidate?.lastname && errors.candidate?.lastname
              }
              isValid={
                touchedFields.candidate?.lastname &&
                errors.candidate?.lastname === undefined
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.candidate?.lastname?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>1ste Git repository</Form.Label>
            <Form.Control
              type="text"
              placeholder="Git repository"
              {...register('repository1', { required: !zipfile1 })}
              isInvalid={touchedFields.repository1 && errors.repository1}
              isValid={
                touchedFields.repository1 && errors.repository1 === undefined
              }
            />
            <Form.Text className="text-muted">
              Vul hier een github/bitbucket/gitlab of een ander git repository
              link in.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.repository1?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>2de Git repository</Form.Label>
            <Form.Control
              type="text"
              placeholder="Secundaire Git repository"
              disabled={!repository1}
              {...register('repository2')}
              isInvalid={touchedFields.repository2 && errors.repository2}
              isValid={
                touchedFields.repository2 && errors.repository2 === undefined
              }
            />
            <Form.Text className="text-muted">
              Vul hier een secundaire github/bitbucket/gitlab of een ander git
              repository link in.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.repository2?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>Code Zipfile</Form.Label>
            <div className="custom custom-file">
              <input type="hidden" name="zipfile1" {...register('zipfile1')} />
              <ReactS3Uploader
                id="zipfile1"
                className="custom-file-input"
                getSignedUrl={async (file, callback) => {
                  const response = await api.getUploadUrl(file.name, file.type);
                  // return must be an object containing the signedUrl key
                  console.log(response);
                  callback(response);
                }}
                onFinish={(signedUrlData, file) => {
                  // console.log('onFinish');
                  console.log(signedUrlData, file);
                  const signedUrl = new URL(signedUrlData.signedUrl);
                  setValue('zipfile1', signedUrl.pathname);
                  // trigger revalidates repository1, which might be empty, and will then be invalid without retriggering.
                  // this is ugly and makes users uncertain because it shows as red/invalid while it is in fact valid/green
                  // after uploading a file succesfully
                  trigger('repository1');
                  setUploadLabel(`Geupload: ${file.name}`);
                }}
                onError={(message) => {
                  console.error(message);
                }}
                onProgress={(percent, message, file) => {
                  setUploadLabel(`${percent} %`);
                }}
                autoUpload
              />
              <label htmlFor="zipfile1" className="custom-file-label">
                {uploadLabel}
              </label>
            </div>
            {/* <Form.File id="custom-file" label="Zipfile" custom /> */}
            <Form.Text className="text-muted">Upload maximaal 5 MB.</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.zipfile1?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
            <Form.Label>Review type</Form.Label>
            <Form.Control
              as="select"
              {...register('reviewTypeId', { required: true })}
              isInvalid={touchedFields.reviewTypeId && errors.reviewTypeId}
              isValid={
                touchedFields.reviewTypeId && errors.reviewTypeId === undefined
              }
            >
              {reviewTypes.map((reviewType) => (
                <option value={reviewType.id}>
                  {reviewType.name}: {reviewType.description} (EUR{' '}
                  {reviewType.price_with_tax} incl. BTW)
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Button variant="primary" type="submit">
          Codereview Aanvragen
        </Button>
      </fieldset>
    </Form>
  );
};

export default CodeReviewForm;
