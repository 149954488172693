import React from 'react';
import { useCookieConsent } from '../hooks/cookie-consent';

const CookieWall = () => {
  const { consentGiven, giveConsent } = useCookieConsent();

  if (consentGiven) {
    return null;
  }

  return (
    <>
      <div
        id="cookies-corner-modal"
        tabIndex="0"
        className="overflow-y-auto overflow-x-hidden fixed bottom-0 right-0"
      >
        <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="p-5 text-sm font-light text-gray-500 dark:text-gray-400">
              <p className="mb-2">
                Welkom! Bedankt voor uw interesse in Niveaubepaling.nl. Wij
                maken op onze websites gebruik van cookies. Wij gebruiken
                cookies voor het bijhouden van statistieken, om jouw voorkeuren
                op te slaan, maar ook voor marketingdoeleinden (bijvoorbeeld het
                afstemmen van advertenties).
              </p>
              {/* <p> */}
              {/*   Wil je je voorkeuren aanpassen, klik dan op ‘Zelf instellen’. */}
              {/* </p> */}
              <p>
                {' '}
                Door op ‘Alle cookies toestaan’ te klikken, ga je akkoord met
                het gebruik van alle cookies zoals omschreven in onze
                Cookieverklaring.
              </p>
              {/* <p>Je kunt je toestemming op elk moment wijzigen of intrekken.</p> */}
            </div>
            <div className="justify-between items-center p-6 pt-0 space-y-4 sm:flex sm:space-y-0">
              {/* <button */}
              {/*   id="close-modal" */}
              {/*   type="button" */}
              {/*   className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-slate-700 sm:w-auto hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800" */}
              {/* > */}
              {/*   Zelf instellen */}
              {/* </button> */}
              <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
                <button
                  id="block-cookies"
                  type="button"
                  onClick={() => giveConsent(false)}
                  className="py-2 px-4 w-full text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-slate-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Weigeren
                </button>
                <button
                  id="accept-cookies"
                  type="button"
                  onClick={() => giveConsent(true)}
                  className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-slate-700 sm:w-auto hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                >
                  Alle cookies toestaan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieWall;
