import JDLJ9US9 from '../icons/JDLJ9US9';
import YN8ZWJB1 from '../icons/YN8ZWJB1';
import SCJVKV3K from '../icons/SCJVKV3K';
import P5VMHB2X from '../icons/P5VMHB2X';
import Q54LDTCH from '../icons/Q54LDTCH';
import HLQV0PJ0 from '../icons/HLQV0PJ0';
import TVEJRVKR from '../icons/TVEJRVKR';
import FacebookIcon from '../icons/R2Y45I74';
import InstagramIcon from '../icons/L870FFDG';
import TwitterIcon from '../icons/BNXYU3CP';
import GithubIcon from '../icons/GithubIcon';

import React, { useState } from 'react';
import Contact from './Contact';
import { Link } from 'react-router-dom';

const Home2 = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <>
      {/* Navbar */}
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="#" className="flex items-center">
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Niveaubepaling.nl
            </span>
          </a>
          <button
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded={isNavbarOpen}
          >
            <span className="sr-only">Open main menu</span>
            <JDLJ9US9 />
          </button>
          <div
            className={`${
              isNavbarOpen ? '' : 'hidden'
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="#"
                  className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Over ons
                </a>
              </li>
              <li>
                <a
                  href="#process"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Proces
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header id="home" className="bg-gray-900 text-white">
        <div className="max-w-screen-xl px-4 py-32 mx-auto lg:h-screen lg:flex lg:items-center">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl dark:text-gray-200 tracking-tight">
              Niveaubepaling.nl
              <span className="inline-block font-extrabold text-blue-500 sm:block">
                Professionele CodeReviews
              </span>
            </h1>
            <p className="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48">
              Selecteer de beste IT-kandidaten met onze gedetailleerde
              code-analyses
            </p>
            <div className="flex flex-wrap justify-center gap-4 mt-8">
              <a
                href="#contact"
                className="block w-full px-12 py-3 text-sm font-medium text-white bg-blue-600 rounded shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
              >
                Neem contact op
              </a>
              <a
                href="#process"
                className="block w-full px-12 py-3 text-sm font-medium border border-white text-white rounded shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500 sm:w-auto"
              >
                Leer meer
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* About Section */}
      <section id="about" className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
              Waarom kiezen voor Niveaubepaling.nl?
            </h2>
            <p className="mb-4 font-light">
              Hier zijn een paar redenen waarom u voor Niveaubepaling.nl zou
              moeten kiezen:
            </p>
            <ul className="space-y-4 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>
                <span className="font-semibold text-gray-900 dark:text-white">
                  Een unieke dienst voor het vinden van de beste kandidaten:
                </span>{' '}
                Bij Niveaubepaling.nl analyseren we de code van de kandidaten en
                bepalen hun niveau op basis van een grondige en gedetailleerde
                beoordeling van hun programmeervaardigheden.
              </li>
              <li>
                <span className="font-semibold text-gray-900 dark:text-white">
                  Optimaliseer uw recruitmentproces:
                </span>{' '}
                Met onze dienst kunt u erop vertrouwen dat u alleen de meest
                gekwalificeerde kandidaten aan uw klanten presenteert. Door onze
                uitgebreide code-analyses weet u zeker dat de kandidaat voldoet
                aan de eisen van de vacature. Ook is het mogelijk om een
                inschatting te maken van het niveau van de kandidaat zodat u
                weet of de kandidaat past bij de functie.
              </li>
              <li>
                <span className="font-semibold text-gray-900 dark:text-white">
                  Help uw kandidaten om te slagen:
                </span>{' '}
                Uw kandidaat is al een aantal keer afgewezen na het maken van
                assessments die tegenwoordig onderdeel zijn van
                sollicitatierondes, maar mag telkens geen feedback ontvangen.
                Een codereview van Niveaubepaling.nl geeft de kandidaat inzicht
                in zijn/haar sterke en zwakke punten en helpt de kandidaat om te
                groeien en te slagen in toekomstige assessments.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section id="process" className="bg-gray-50 dark:bg-gray-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Hoe werkt het?
            </h2>
            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              Ons proces is eenvoudig en efficiënt, zodat u snel een duidelijk
              beeld krijgt van de capaciteiten van uw kandidaat.
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <YN8ZWJB1 />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 1: Contact opnemen
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Stel een e-mail op naar{' '}
                <a
                  href="mailto:info@niveaubepaling.nl"
                  className="text-blue-600 hover:underline"
                >
                  info@niveaubepaling.nl
                </a>
                , in de volgende stappen leggen we uit welke informatie benodigd
                is.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <SCJVKV3K />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 2: Prioriteit aangeven
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Geef aan de codereview met spoed (
                <span title="incl. BTW">€149,99</span> binnen 24 uur) of met
                normale prioriteit (<span title="incl. BTW">€79,99</span> binnen
                3 werkdagen) behandeld moet worden.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <P5VMHB2X />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 3: Code aanleveren
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Voeg een link toe naar de code, een link naar een zipfile of
                voeg de code als bijlage toe aan de e-mail.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <Q54LDTCH />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 4: Hulpvraag
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Geef aan wat uw hulpvraag is. Misschien wilt u weten of de code
                voldoet aan de eisen van de assessment die uw kandidaat gemaakt
                heeft. Of misschien wilt u graag het niveau controleren van de
                kandidaat.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <HLQV0PJ0 />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 5: Code review
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                U verstuurd de mail met de code en de hulpvraag naar ons. Wij
                voeren een codereview uit en schrijven min- en pluspunten op en
                geven een inschatting van het niveau van de kandidaat. U
                ontvangt reactie binnen 24 uur bij een spoedreview en binnen 3
                werkdagen bij een normale review.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                <Q54LDTCH />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">
                Stap 6: Resultaat
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                U ontvangt een e-mail met de resultaten van de codereview.
                Hierin staat een overzicht van de min- en pluspunten van de code
                en een inschatting van het niveau van de kandidaat.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <Contact />

      {/* Footer */}
      <footer className="p-4 bg-white sm:p-6 dark:bg-gray-900">
        <div className="mx-auto max-w-screen-xl">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <Link to="/" className="flex items-center">
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                  Niveaubepaling.nl
                </span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Links
                </h2>
                <ul className="text-gray-600 dark:text-gray-400">
                  <li className="mb-4">
                    <a href="#home" className="hover:underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" className="hover:underline">
                      Over ons
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Diensten
                </h2>
                <ul className="text-gray-600 dark:text-gray-400">
                  <li className="mb-4">
                    <a href="#process" className="hover:underline">
                      Proces
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="hover:underline">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Legal
                </h2>
                <ul className="text-gray-600 dark:text-gray-400">
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline">
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              &copy; 2024 Niveaubepaling.nl. Alle rechten voorbehouden.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              <a
                href="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <FacebookIcon />
              </a>
              <a
                href="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <InstagramIcon />
              </a>
              <a
                href="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <TwitterIcon />
              </a>
              <a
                href="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <GithubIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home2;
